import React, { useState, useEffect, useCallback } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Field } from "formik";
import { Formik } from "formik";

import requests from "../../../../../services/requests";
import { StageIcons } from "../../../../../components/Icons";
import DownloadIcon from "../../../../../assets/svgs/download.inline.svg";
import ChatBoxIcon from "../../../../../assets/svgs/chatbox.inline.svg";
import DocumentIcon from "../../../../../assets/svgs/document.inline.svg";
import NotificationIcon from "../../../../../assets/svgs/notifications.inline.svg";
import SimpleCollapse from "../../../../../components/Collapse/SimpleCollapse";
import { getPicture } from "../../../../../assets/utils/picture";

const yup = require("yup");

function Updates({ process, actionComments, setActionComments }) {
    const [loadUpdates, setLoadUpdates] = useState(false);
    const [updates, setUpdates] = useState([]);
    const getUpdates = () => {
        if (!process || !process.identifier) {
            return;
        }

        setLoadUpdates(true);
        requests
            .getProcessUpdates(process.identifier)
            .then(data => {
                setUpdates(data);
                setLoadUpdates(false);
            })
            .catch(() => setLoadUpdates(false));
    };

    const [downloadingFile, setDownloadingFile] = useState("");
    const downloadFile = useCallback((identifier, filename, ext) => {
        setDownloadingFile(identifier);

        let link = document.createElement("a");

        link.href = (requests.getApiUrl() + `/open/process/file/${process.identifier}/${identifier}/download`).replace(
            "//open",
            "/open"
        );
        link.target = "_blank";
        link.download = filename + "." + ext;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadingFile("");
    });

    useEffect(() => {
        getUpdates();
    }, [actionComments]);

    return (
        <div className="w-full overflow-auto lg:w-3/5 lg:border-r lg:border-C3 lg:pr-4">
            <h5 className="mb-4">ATUALIZAÇÕES DO PROCESSO</h5>
            <>
                {loadUpdates ? (
                    <div className="flex flex-col items-center">
                        <ClipLoader size={20} loading={loadUpdates} />
                    </div>
                ) : (
                    <>
                        {updates?.length > 0 &&
                            updates.map((entity, key) => {
                                return (
                                    <article key={key}>
                                        <SimpleCollapse
                                            visible={
                                                <div className="flex ml-2">
                                                    <div>
                                                        <div
                                                            className={`border border-${
                                                                entity.isPast || entity.isCurrent
                                                                    ? process.returnOrigin
                                                                        ? "rosa_oficial"
                                                                        : "roxo_oficial"
                                                                    : "C4"
                                                            } rounded-full w-12 h-12 flex justify-center items-center bg-white`}
                                                        >
                                                            <StageIcons
                                                                stage={entity.stage.description}
                                                                modality={entity.modality}
                                                                className={`text-${
                                                                    entity.isPast || entity.isCurrent
                                                                        ? process.returnOrigin
                                                                            ? "rosa_oficial"
                                                                            : "roxo_oficial"
                                                                        : "C4"
                                                                } fill fill-current w-6 h-5 bg-white`}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="ml-4 cursor-pointer">
                                                        <h6>{entity.stage.description}</h6>
                                                        <article className="flex">
                                                            <div className="flex mr-6">
                                                                <DocumentIcon className="mr-2 w-3 text-C4 fill-current cursor-pointer" />
                                                                <legend className="font-normal mr-1">
                                                                    {entity.files ? entity.files.length : "0"}
                                                                </legend>
                                                                <legend className="font-normal lg:inline hidden">
                                                                    anexos
                                                                </legend>
                                                            </div>
                                                            <div className="flex mr-6">
                                                                <NotificationIcon className="mr-2 w-3 text-C4 fill-current cursor-pointer" />
                                                                <legend className="font-normal mr-1">
                                                                    {entity.alerts ? entity.alerts.length : "0"}
                                                                </legend>
                                                                <legend className="font-normal lg:inline hidden">
                                                                    atualizações
                                                                </legend>
                                                            </div>
                                                            <div className="flex mr-6">
                                                                <ChatBoxIcon className="mr-2 w-3 text-C4 fill-current cursor-pointer" />

                                                                <legend className="font-normal mr-1">
                                                                    {entity.comments ? entity.comments.length : "0"}
                                                                </legend>
                                                                <legend className="font-normal lg:inline hidden">
                                                                    comentários
                                                                </legend>
                                                            </div>
                                                            <div className="flex">
                                                                <legend className="whitespace-no-wrap">
                                                                    {entity.accomplishedAt ? (
                                                                        <>Realização: {entity.accomplishedAt}</>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </legend>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            }
                                            invisible={
                                                <>
                                                    <div className="flex ml-6">
                                                        <div>
                                                            <div
                                                                className={`ml-1 border-l border-${
                                                                    process.returnOrigin ? "rosa_oficial" : "roxo_oficial"
                                                                } h-8`}
                                                            ></div>
                                                            <div
                                                                className={`border border-${
                                                                    process.returnOrigin ? "rosa_oficial" : "roxo_oficial"
                                                                } h-2 w-2 rounded-full bg-white`}
                                                            ></div>
                                                            <div
                                                                className={`ml-1 border-l border-${
                                                                    process.returnOrigin ? "rosa_oficial" : "roxo_oficial"
                                                                } h-full pb-1`}
                                                            ></div>
                                                        </div>
                                                        <div className="pl-3 w-full ">
                                                            <div className=" border-t border-C4 py-2">
                                                                <SimpleCollapse
                                                                    visible={
                                                                        <div className="flex cursor-pointer">
                                                                            <div className="w-full">
                                                                                <h6>Arquivos enviados</h6>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    invisible={
                                                                        <article className="flex">
                                                                            <ul>
                                                                                {entity.files && entity.files.length > 0 ? (
                                                                                    entity.files.map(doc => {
                                                                                        return (
                                                                                            <li key={doc.identifier}>
                                                                                                {doc.uploadName}
                                                                                                <button
                                                                                                    className="p-1 ml-2 rounded-md bg-roxo_oficial_light"
                                                                                                    onClick={() =>
                                                                                                        downloadFile(
                                                                                                            doc.identifier,
                                                                                                            doc.name,
                                                                                                            doc.ext
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {!downloadingFile ||
                                                                                                    downloadingFile !==
                                                                                                        doc.identifier ? (
                                                                                                        <DownloadIcon />
                                                                                                    ) : (
                                                                                                        <ClipLoader
                                                                                                            size={15}
                                                                                                            loading={true}
                                                                                                        />
                                                                                                    )}
                                                                                                </button>
                                                                                            </li>
                                                                                        );
                                                                                    })
                                                                                ) : (
                                                                                    <li>Sem atualizações</li>
                                                                                )}
                                                                            </ul>
                                                                        </article>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex ml-6">
                                                        <div>
                                                            <div
                                                                className={`ml-1 border-l border-${
                                                                    process.returnOrigin ? "rosa_oficial" : "roxo_oficial"
                                                                } h-8`}
                                                            ></div>
                                                            <div
                                                                className={`border border-${
                                                                    process.returnOrigin ? "rosa_oficial" : "roxo_oficial"
                                                                } h-2 w-2 rounded-full bg-white`}
                                                            ></div>
                                                            <div
                                                                className={`ml-1 border-l border-${
                                                                    process.returnOrigin ? "rosa_oficial" : "roxo_oficial"
                                                                } h-full`}
                                                            ></div>
                                                        </div>
                                                        <div className="pl-3 w-full  ">
                                                            <div className="border-t border-C4 py-2">
                                                                <SimpleCollapse
                                                                    visible={
                                                                        <div className="flex cursor-pointer">
                                                                            <div className="w-full">
                                                                                <h6>Atualizações durante esta etapa</h6>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    invisible={
                                                                        <article className="flex">
                                                                            <ul>
                                                                                {entity.alerts &&
                                                                                entity.alerts.length > 0 ? (
                                                                                    entity.alerts.map(entity => {
                                                                                        return (
                                                                                            <li
                                                                                                key={entity.identifier}
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: entity.description,
                                                                                                }}
                                                                                            ></li>
                                                                                        );
                                                                                    })
                                                                                ) : (
                                                                                    <li>Sem atualizações</li>
                                                                                )}
                                                                            </ul>
                                                                        </article>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex ml-6">
                                                        <div>
                                                            <div
                                                                className={`ml-1 border-l border-${
                                                                    process.returnOrigin ? "rosa_oficial" : "roxo_oficial"
                                                                } h-8`}
                                                            ></div>
                                                            <div
                                                                className={`border border-${
                                                                    process.returnOrigin ? "rosa_oficial" : "roxo_oficial"
                                                                } h-2 w-2 rounded-full bg-white`}
                                                            ></div>
                                                            <div
                                                                className={`ml-1 border-l border-${
                                                                    process.returnOrigin ? "rosa_oficial" : "roxo_oficial"
                                                                } h-full`}
                                                            ></div>
                                                        </div>
                                                        <div className="pl-3 w-full ">
                                                            <div className=" border-t border-C4 py-2">
                                                                <SimpleCollapse
                                                                    visible={
                                                                        <div className="flex cursor-pointer">
                                                                            <div className="w-full pb-5">
                                                                                <h6>Comentários nesta etapa</h6>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    invisible={
                                                                        <>
                                                                            <div className="overflow-y-auto h-5rem">
                                                                                {entity.comments &&
                                                                                    entity.comments.length > 0 &&
                                                                                    entity.comments.map(entity => {
                                                                                        return (
                                                                                            <div
                                                                                                className="flex mb-3"
                                                                                                key={entity.identifier}
                                                                                            >
                                                                                                <div className="w-1/12 pr-1">
                                                                                                    <div>
                                                                                                        <img
                                                                                                            className={`border border-${
                                                                                                                process.returnOrigin
                                                                                                                    ? "rosa_oficial"
                                                                                                                    : "roxo_oficial"
                                                                                                            } rounded-full w-12 h-12 flex justify-center items-center bg-white`}
                                                                                                            src={getPicture(
                                                                                                                entity.userProfilePictureUri
                                                                                                            )}
                                                                                                            alt={
                                                                                                                entity.userName
                                                                                                            }
                                                                                                            onError={e => {
                                                                                                                let tempPicture =
                                                                                                                    e.target
                                                                                                                        .src;
                                                                                                                if (
                                                                                                                    tempPicture.indexOf(
                                                                                                                        "http://localhost:8001"
                                                                                                                    ) >= 0
                                                                                                                ) {
                                                                                                                    tempPicture =
                                                                                                                        tempPicture.replace(
                                                                                                                            "http://localhost:8001",
                                                                                                                            "https://api.vendemmia.com.br"
                                                                                                                        );
                                                                                                                } else if (
                                                                                                                    tempPicture.indexOf(
                                                                                                                        "http://localhost:8000"
                                                                                                                    ) >= 0
                                                                                                                ) {
                                                                                                                    tempPicture =
                                                                                                                        tempPicture.replace(
                                                                                                                            "http://localhost:8000",
                                                                                                                            "https://api.vendemmia.com.br"
                                                                                                                        );
                                                                                                                }

                                                                                                                if (
                                                                                                                    tempPicture !==
                                                                                                                    e.target
                                                                                                                        .src
                                                                                                                ) {
                                                                                                                    e.target.src =
                                                                                                                        tempPicture;
                                                                                                                }

                                                                                                                window.localStorage.removeItem(
                                                                                                                    "temp-profile-picture"
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="w-11/12">
                                                                                                    <h6>
                                                                                                        {entity.userName}
                                                                                                    </h6>
                                                                                                    <p className="font-normal">
                                                                                                        {entity.description}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                            </div>
                                                                            <div className="flex border-t border-C2 pt-2">
                                                                                <div className="w-full">
                                                                                    <Formik
                                                                                        enableReinitialize={true}
                                                                                        initialValues={{
                                                                                            comment: "",
                                                                                        }}
                                                                                        validationSchema={yup
                                                                                            .object()
                                                                                            .shape({
                                                                                                comment: yup
                                                                                                    .string()
                                                                                                    .required(
                                                                                                        "Campo obrigatório"
                                                                                                    ),
                                                                                            })}
                                                                                        onSubmit={(
                                                                                            values,
                                                                                            {
                                                                                                setFieldError,
                                                                                                setSubmitting,
                                                                                                resetForm,
                                                                                            }
                                                                                        ) => {
                                                                                            setSubmitting(true);

                                                                                            requests
                                                                                                .addProcessStageComment(
                                                                                                    process.identifier,
                                                                                                    entity.stage.sequential,
                                                                                                    {
                                                                                                        description:
                                                                                                            values.comment,
                                                                                                        hasBeenRead: 0,
                                                                                                    }
                                                                                                )
                                                                                                .then(() => {
                                                                                                    setSubmitting(false);
                                                                                                    getUpdates();
                                                                                                    resetForm();
                                                                                                    setActionComments(
                                                                                                        !actionComments
                                                                                                    );
                                                                                                });
                                                                                        }}
                                                                                    >
                                                                                        {({
                                                                                            handleChange,
                                                                                            handleSubmit,
                                                                                            isSubmitting,
                                                                                            errors,
                                                                                            values,
                                                                                            touched,
                                                                                            handleBlur,
                                                                                            setFieldValue,
                                                                                        }) => (
                                                                                            <form onSubmit={handleSubmit}>
                                                                                                <Field
                                                                                                    id={`comment-${entity.stage.identifier}`}
                                                                                                    name="comment"
                                                                                                    component="textarea"
                                                                                                    className="w-full rounded-md py-1 px-5 border border-C2 rounded-md p-1 mb-2 h-9"
                                                                                                    rows="3"
                                                                                                />
                                                                                                <button
                                                                                                    className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-1"
                                                                                                    type="submit"
                                                                                                    disabled={isSubmitting}
                                                                                                >
                                                                                                    {isSubmitting ? (
                                                                                                        <>
                                                                                                            Enviando{" "}
                                                                                                            <ClipLoader
                                                                                                                size={10}
                                                                                                                loading={
                                                                                                                    isSubmitting
                                                                                                                }
                                                                                                            />
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>Enviar</>
                                                                                                    )}
                                                                                                </button>
                                                                                            </form>
                                                                                        )}
                                                                                    </Formik>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        />
                                    </article>
                                );
                            })}
                        {updates.length === 0 && (
                            <div className="flex flex-col items-center">Sem atualizações neste processo.</div>
                        )}
                    </>
                )}
            </>
        </div>
    );
}

export default Updates;
